<template>
    <div class="quality">
        <div class="qua-1">
            <img src="https://khome2.tuzuu.com/vita/ahome_1.png">
            <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo">
            <div class="title">精准科学｜最适合“你的”才是最好的</div>
        </div>
        <div class="qua-2">
            <div class="column-title flex" style="margin-bottom: 17px">
                关于UitaUita
            </div>
            <div class="desc">
                「UitaUita」始创于澳洲，前身是一群由国际持证营养师、执业医师、基因科学家等组织在一起的健康管理工作室，通过互联网给北美、欧洲的个人客户和企业客户提供健康咨询与健康改善方案。
            </div>
            <div class="desc">
                2019年初，我们开始和中国一流的基因科技公司和检测科技公司开展合作，为中国用户通过基因检测、代谢检测等精准手段，定制“一人一案”的精准营养方案。
            </div>
            <div class="desc" style="margin-bottom: 14px">
                在长达两年的实践中，我们通过大数据分析，发现中国人在饮食习惯、生活习惯和工作习惯上与欧美人有极大的差异，同时在相关基因数据上也存在显著差异性，因此中国人的营养品配方，不能完全按照欧美人群来设计，必须进行精准化的改良定制。
            </div>
            <div class="column-title flex" style="margin-bottom: 20px">
                部分顾问团队
            </div>
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#666666">
                <van-swipe-item class="swipe-item" v-for="(item,index) in list" :key="index">
                    <div class="flex-column-center">
                        <van-image width="160px" round :src="item.image"/>
                        <div class="position">{{ item.desc }}</div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                    <ul class="list">
                        <li v-for="(info,index) in item.info" :key="index">
                            {{ info }}
                        </li>
                    </ul>
                </van-swipe-item>
            </van-swipe>

            <div class="desc">
                <p>2020年，我们确定了中国境内具备高度行业专业性和互联网运营经验的运营合作伙伴——中康未来健康科技有限公司，同时，它也是博鳌乐城国际医疗先行区理事长单位和博鳌乐城医疗健康产业促进会理事单位。</p>
                <p>其中，海南博鳌乐城国际医疗先行区，被誉为“博鳌亚洲论坛第二乐章”，2018年4月11日，习近平总书记考察博鳌乐城国际医疗先行区，对其与国际接轨的先进医疗水平表示肯定。</p>
                <p>博鳌乐城医疗先行区通过国家赋予的“国九条”政策，以特许医疗、特许研究、特许经营、特许国际医疗交流的“4个特许”政策优惠，来实现医疗技术、设备、药品与国际先进水平“三同步”，是目前全国唯一的“医疗特区”。</p>
                <p>博鳌乐城医疗健康产业促进会共享“医疗特区”的国际化医疗资源优势，利用先行区内顶级的医疗团队、设施设备，为客户提供综合医疗健康服务。</p>
            </div>
            <div class="column-title flex">
                检测合作方：
            </div>
            <img src="https://khome2.tuzuu.com/vita/logo2.png" style="width: 50%;margin: 25px 0px">
            <div class="desc">
                我们相信精准科学的力量，并以此作为基础，给予用户每日营养定制计划。
            </div>
            <div class="desc">
                <p>我们的基因检测的合作公司，迪安诊断，于2011年在中国A股上市。</p>
                <p>迪安诊断专注于诊断技术平台创新，获批中国首批肿瘤诊断与治疗项目高通量基因测序技术试点单位、中国首批基因检测技术临床应用示范中心、中国第三方高性能医学诊疗设备应用示范中心。</p>
            </div>
            <div class="desc">
                霍普金斯大学、美国丹纳赫集团、美国FMI、美国Agena、美国司法鉴定机构NMS、美国马里兰州司法局等多家国际知名机构达成战略合作。目前已在总过布局40家连锁化实验室，为中国超过20000家医疗机构、3亿人提供服务。
            </div>

            <div class="footer flex-between">
                <img src="https://khome2.tuzuu.com/vita/logo_3.png">
                <div>
                    <p>「UitaUita」——每日营养专家</p>
                    <p>Simple One，Simple Health</p>
                    <p>每天一包，让健康简简单单</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                list: [
                    {
                        image: 'https://khome2.tuzuu.com/vita/preson_4.png',
                        name: '营养学博士',
                        desc: '布伦达·佩拉塔·罗梅罗',
                        info: [
                            '认证注册营养师、营养基因学和营养遗传学专家',
                            '5年临床营养评估、膳食计划和营养治疗经验',
                            '3年糖尿病患者营养治疗经验'
                        ]
                    },
                    {
                        image: 'https://khome2.tuzuu.com/vita/preson_5.png',
                        name: '营养学博士',
                        desc: '凯瑟琳·斯·格瓦西奥',
                        info: [
                            '认证注册营养师、精准营养认证教练、运动营养认证教练',
                            '16年饮食与健康营养顾问专家经验'
                        ]
                    },
                    {
                        image: 'https://khome2.tuzuu.com/vita/preson_6.png',
                        name: '麦吉尔大学心理学学士',
                        desc: '莎拉·索米安',
                        info: [
                            '加拿大整体营养研究所营养专家',
                            '预防性健康和慢性疾病营养治疗培训导师'
                        ]
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="less">
    .swipe-item {
    }

    .quality {
        width: 100%;
        min-height: 100vh;
        background-color: #F2F2F2;
        font-size: 16px;
        padding-bottom: 35px;
        letter-spacing: 0.5px;
        line-height: 25px;

        .qua-1 {
            position: relative;

            .title {
                position: absolute;
                font-size: 18px;
                font-weight: 700;
                color: white;
                top: -13px;
                bottom: 0;
                height: fit-content;
                margin: auto;
                width: 100%;
                text-align: center;
            }
        }

        .qua-2 {
            width: calc(100% - 20px);
            margin: auto;
            background-color: white;
            border-radius: 10px;
            margin-top: -60px;
            position: relative;
            z-index: 2;
            padding: 20px 15px 25px 15px;
            box-sizing: border-box;

            .column-title {
                font-size: 18px;
                color: #292C2F;
                font-weight: 700;
                margin-top: 20px;

                &:first-of-type {
                    margin-top: 0px;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 5px;
                    height: 20px;
                    background-color: #6B00AF;
                    border-radius: 8px;
                    margin-right: 5px;
                }
            }

            .desc {
                font-size: 14px;
                color: #7e7e7e;
                line-height: 27px;
                margin-top: 10px;
            }

            .my-swipe {
                padding-bottom: 30px;
                margin-bottom: 20px;

                /deep/ .van-swipe__indicator--active {
                    width: 30px;
                    border-radius: 10px;
                    transition: all 0.4s;
                }

                /deep/ .van-swipe__indicator {
                    background-color: #D8D8D8;
                }

                .position {
                    margin-top: 25px;
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #292C2F;
                }

                .name {
                    color: #3F3F3F;
                    margin-top: 10px;
                    font-size: 13px;
                }

                .list {
                    margin-top: 14px;
                    font-size: 14px;
                    color: #232323;
                    line-height: 30px;

                    li {
                        display: flex;

                        &:before {
                            content: '-';
                            display: block;

                        }
                    }
                }
            }

            .hr {
                width: 100%;
                height: 1px;
                //background-color: #292C2F;
                margin-top: 25px;
                margin-bottom: 19px;
            }

        }

        .footer {
            margin-top: 40px;
            font-size: 12px;
            color: #3F3F3F;
            line-height: 22px;
            text-align: right;
            font-weight: 700;

            img {
                width: 33%;
            }

        }

        img {
            width: 100%;
            display: block;
        }
    }
</style>